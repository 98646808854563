@font-face {
  font-family: 'Inter';
  src: url('./Inter-Light.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./Inter-SemiBold.ttf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Inter-Regular';
  src: url('./Inter-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./Inter-Medium.ttf'); /* IE9 Compat Modes */
}